import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import indexStore from '../mobx/store';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import './ListDetailPage.scss';
import { API_HOST_PREFIX } from '../constants/common';

export default function ListDetailPage() {
  const urlParams = useParams();
  const { popupStore } = indexStore();
  const isLoading = popupStore.isLoading;
  const loadingImg = require('../images/loading.gif');

  // const [list, setList] = useState<Object>({});
  const [deployer, setDeployer] = useState<string | number | undefined>('');
  const [tokenAddress, setTokenAddress] = useState<string | number | undefined>('');
  const [whitelistToken, setWhitelistToken] = useState<String>('false');
  const [id, setId] = useState<string | number | undefined>(0);
  const [projectName, setProjectName] = useState<string | number | undefined>('');
  const [tokenName, setTokenName] = useState<string | number | undefined>('');
  const [tokenType, setTokenType] = useState<string | number | undefined>(0);

  const [isReject, setIsReject] = useState<boolean>(false);

  // token social info
  const [websiteURL, setWebsiteURL] = useState<string | number | undefined>('');
  const [emailAddress, setEmailAddress] = useState<string | number | undefined>('');
  const [whitepaper, setWhitepaper] = useState<string | number | undefined>('');
  const [gitHub, setGitHub] = useState<string | number | undefined>('');
  const [reddit, setReddit] = useState<string | number | undefined>('');
  const [twitter, setTwitter] = useState<string | number | undefined>('');
  const [discord, setDiscord] = useState<string | number | undefined>('');
  const [telegram, setTelegram] = useState<string | number | undefined>('');
  const [weChat, setWeChat] = useState<string | number | undefined>('');
  const [facebook, setFacebook] = useState<string | number | undefined>('');
  const [slack, setSlack] = useState<string | number | undefined>('');
  const [linkedin, setLinkedin] = useState<string | number | undefined>('');
  const [bitcointalk, setBitcointalk] = useState<string | number | undefined>('');
  const [medium, setMedium] = useState<string | number | undefined>('');
  const [opensea, setOpensea] = useState<string | number | undefined>('');
  const [ticketing, setTicketing] = useState<string | number | undefined>('');
  const [priceData, setPriceData] = useState<string | number | undefined>('');

  const [coinMarketCap, setCoinMarketCap] = useState<string | number | undefined>('');
  const [coinGecko, setCoinGecko] = useState<string | number | undefined>('');

  const [noImg] = useState(require('../images/noImg.jpg'));
  const [icon, setIcon] = useState<any>(null);
  const [iconInfo, setIconInfo] = useState<any>([]);
  const [iconPreview, setIconPreview] = useState<any>(null);

  const navigator = useNavigate();
  useEffect(() => {
    getTokenDetail();
    if (window.location.pathname.includes('reject')) {
      setIsReject(true);
    }
  }, [urlParams.address]);

  function confirmPopup(message: string, method?: Function) {
    popupStore.setConfirm(true);
    popupStore.setMessage(message);
    method ? popupStore.setMethod(method) : popupStore.setMethod(() => null);
    popupStore.setToggle(true);
  }

  function alertPopup(message: string) {
    popupStore.setConfirm(false);
    popupStore.setMessage(message);
    popupStore.setMethod(() => null);
    popupStore.setToggle(true);
  }

  async function getTokenDetail() {
    const URL = `${API_HOST_PREFIX}/token/${urlParams.address}`;
    await fetch(URL, { credentials: 'include' })
      .then((res) => res.json())
      .then((json) => {
        if (json.result) {
          setTokenDetail(json.result);
        }
      })
      .catch((e) => {
        console.warn(e);
      });
  }

  function setTokenDetail(result: any) {
    const {
      id,
      deployer,
      filename,
      icon,
      iconMimeType,
      socialProfiles,
      tokenAddress,
      projectName,
      tokenName,
      tokenType
    } = result;
    let social;
    if (socialProfiles) {
      try {
        social = JSON.parse(socialProfiles);
        setWebsiteURL(social.websiteURL || '');
        setEmailAddress(social.emailAddress || '');
        setWhitepaper(social.whitepaper || '');
        setTwitter(social.twitter || '');
        setGitHub(social.gitHub || '');
        setReddit(social.reddit || '');
        setTwitter(social.twitter || '');
        setDiscord(social.discord || '');
        setTelegram(social.telegram || '');
        setWeChat(social.weChat || '');
        setFacebook(social.facebook || '');
        setSlack(social.slack || '');
        setLinkedin(social.linkedin || '');
        setBitcointalk(social.bitcointalk || '');
        setMedium(social.medium || '');
        setOpensea(social.opensea || '');
        setTicketing(social.ticketing || '');
        setPriceData(social.priceData || '');
        setCoinGecko(social.coinGecko || '');
        setCoinMarketCap(social.coinMarketCap || '');
      } catch (e) {
        console.log(e);
      }
    }

    setTokenType(tokenType);
    setDeployer(deployer);
    setTokenAddress(tokenAddress);
    setId(id);
    setProjectName(projectName || '');
    setTokenName(tokenName || '');

    if (filename) {
      let decodedFile = dataURLtoFile(`data:${iconMimeType};base64,${icon}`, filename);
      setIcon([decodedFile]);
      encodeFile(decodedFile);
      setIconInfo([{ name: decodedFile.name, size: decodedFile.size, type: decodedFile.type }]);
    }
  }

  const dataURLtoFile = (dataurl: any, fileName: string) => {
    var arr = dataurl.split(','),
      mime = arr[0]?.match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], fileName, { type: mime });
  };

  function iconChangeHandler(e: any) {
    if (e.target.files.length === 0) return iconDelete;
    if (e.target.files === null) return iconDelete;

    let files = e.target.files;
    let resultFiles = [];

    for (let i = 0; i < files.length; i++) {
      let fileType = files[i].type;
      if (fileType.includes('image')) {
        resultFiles.push(files[i]);
      } else {
        alertPopup('image 형식의 파일이 아닙니다.');
        return;
      }
    }

    let filesInfo = [];
    for (let i = 0; i < resultFiles.length; i++) {
      let fileInfo = {
        name: resultFiles[i].name,
        type: resultFiles[i].type,
        size: resultFiles[i].size
      };
      filesInfo.push(fileInfo);
      encodeFile(resultFiles[i]);
    }
    setIcon(resultFiles);
    setIconInfo(filesInfo);
  }

  function encodeFile(file: File, type?: string) {
    if (!file) {
      return setIconPreview(null);
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise<void>((resolve) => {
      reader.onload = () => {
        setIconPreview(reader.result);
        resolve();
      };
    });
  }

  function iconDelete() {
    if (isReject) return;

    let target = document.getElementById('inputFileIcon') as HTMLInputElement;
    target.value = '';
    setIcon(null);
    setIconInfo([]);
    setIconPreview(null);
  }

  function tokenDetailCancel() {
    SubmitTokenApproval(false);
  }

  async function SubmitTokenApproval(isApprove: boolean) {
    const resultObject = {
      id: id,
      adminEmail: sessionStorage.uid || '',
      isApprove
    };
    const requestOptions = {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(resultObject)
    };

    const URL = `${API_HOST_PREFIX}/tokenList/${tokenAddress}`;
    // @ts-ignore: Unreachable code error
    await fetch(URL, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        if (json.success === true) {
          alertPopup('거절 되었습니다.');
          navigator(-1);
        } else {
          alertPopup(json.message);
        }
      })
      .catch((e) => {
        console.warn(e);
      });
  }

  async function submitKCTVerification() {
    const resultObject = {
      socialProfiles: {
        websiteURL,
        emailAddress,
        whitepaper,
        gitHub,
        reddit,
        twitter,
        discord,
        telegram,
        weChat,
        facebook,
        slack,
        linkedin,
        bitcointalk,
        medium,
        opensea,
        ticketing,
        priceData,
        coinMarketCap,
        coinGecko
      },
      whitelistFlag: whitelistToken === 'true' ? true : false,
      file: icon,
      id: id,
      adminEmail: sessionStorage.uid || '',
      projectName,
      tokenName: tokenType === 3 || tokenType === 4 ? tokenName : ''
    };
    let formData = new FormData();

    for (const [key, value] of Object.entries(resultObject)) {
      if (key === 'file' && value !== null) {
        for (let i = 0; i < value.length; i++) {
          formData.append('file', value[i]);
        }
      } else {
        if (key === 'socialProfiles') {
          formData.append(key, JSON.stringify(value));
        } else {
          formData.append(key, value);
        }
      }
    }

    const requestOptions = {
      method: 'POST',
      credentials: 'include',
      body: formData
    };

    const URL = `${API_HOST_PREFIX}/token/${tokenAddress}`;
    // @ts-ignore: Unreachable code error
    await fetch(URL, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        if (json.success === true) {
          alertPopup('승인 완료');
          navigator(-1);
        } else {
          alertPopup(json.message);
        }
      })
      .catch((e) => {
        console.warn(e);
      });
  }

  function clickScopeLink(isScope: boolean, url: string | number | undefined) {
    if (!url) return;
    const NETWORK_ID = API_HOST_PREFIX?.includes('cypress') ? 'cypress' : 'baobab';
    if (isScope) {
      const targetHost =
        NETWORK_ID === 'cypress' ? 'https://klaytnscope.com' : 'https://baobab.klaytnscope.com';
      window.open(`${targetHost}/account/${url}`);
    } else {
      window.open(`${url}`);
    }
  }

  return (
    <>
      {!isLoading ? (
        <div className="ListDetailPage">
          <div className="naming">
            <h3>Token Info</h3>
            <div className="row">
              <p className="inputDesc">Token Address</p>
              <input
                value={tokenAddress}
                onChange={(e) => setTokenAddress(e.target.value)}
                className="inputText"
                type="text"
                disabled
                readOnly
              />
              <div className="listBtn btnLink" onClick={() => clickScopeLink(true, tokenAddress)}>
                Link
              </div>
            </div>
            <div className="row">
              <p className="inputDesc">Deployer</p>
              <input
                value={deployer}
                onChange={(e) => setDeployer(e.target.value)}
                className="inputText"
                type="text"
                disabled
                readOnly
              />
              <div className="listBtn btnLink" onClick={() => clickScopeLink(true, deployer)}>
                Link
              </div>
            </div>

            <div>
              <p className="inputDesc">Whitelist Token</p>
              <div className="input_radioArea">
                <p>
                  <input
                    id="opt7"
                    type="radio"
                    value={'false'}
                    name="opt7"
                    checked={whitelistToken === 'false'}
                    onChange={(e) => {
                      setWhitelistToken(e.target.value);
                    }}
                    disabled={isReject === true}
                  />
                  <label htmlFor="opt7">Non-verified</label>
                </p>
                <p>
                  <input
                    id="opt8"
                    type="radio"
                    value={'true'}
                    name="opt8"
                    checked={whitelistToken === 'true'}
                    onChange={(e) => {
                      setWhitelistToken(e.target.value);
                    }}
                    disabled={isReject === true}
                  />
                  <label htmlFor="opt8">Verified</label>
                </p>
              </div>
            </div>
          </div>

          <div className={`iconUpload ${isReject ? 'reject' : ''}`}>
            <p className="inputDesc top">Icon</p>
            <div className="iconPreview">
              <img alt={iconInfo[0]?.name} src={iconPreview ? iconPreview : noImg} />
            </div>
            <input
              id="inputFileIcon"
              className="inputFile short"
              type="file"
              onChange={(e) => iconChangeHandler(e)}
              disabled={isReject === true}
            />
            <label htmlFor="inputFileIcon">
              <AttachFileIcon />
              {iconInfo[0]?.name || '등록된 파일이 없습니다.'}
            </label>

            <div
              className={`listBtn btnDelete ${isReject ? 'reject' : ''}`}
              onClick={() => iconDelete()}
            >
              Delete
            </div>
          </div>

          <div>
            <p className="inputDesc">Project Name</p>
            <input
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              className="inputText"
              type="text"
              disabled={isReject === true}
            />
          </div>

          {tokenType === 3 || tokenType === 4 ? (
            <div>
              <p className="inputDesc">Token Name</p>
              <input
                value={tokenName}
                onChange={(e) => setTokenName(e.target.value)}
                className="inputText"
                type="text"
                disabled={isReject === true}
              />
            </div>
          ) : (
            <></>
          )}

          <div className="naming">
            <h3>Social Profiles</h3>
            <div className="row">
              <p className="inputDesc">Website URL</p>
              <input
                value={websiteURL}
                onChange={(e) => setWebsiteURL(e.target.value)}
                className="inputText"
                type="text"
                disabled={isReject === true}
              />
              <div className="listBtn btnLink" onClick={() => clickScopeLink(false, websiteURL)}>
                Link
              </div>
            </div>
            <div className="row">
              <p className="inputDesc">E-mail Address</p>
              <input
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
                className="inputText"
                type="text"
                disabled={isReject === true}
              />
              <a className="listBtn btnLink" href={`mailTo:${emailAddress}`}>
                Link
              </a>
            </div>
            <div className="row">
              <p className="inputDesc">Whitepaper</p>
              <input
                value={whitepaper}
                onChange={(e) => setWhitepaper(e.target.value)}
                className="inputText"
                type="text"
                disabled={isReject === true}
              />
              <div className="listBtn btnLink" onClick={() => clickScopeLink(false, whitepaper)}>
                Link
              </div>
            </div>
            <div className="row">
              <p className="inputDesc">GitHub</p>
              <input
                value={gitHub}
                onChange={(e) => setGitHub(e.target.value)}
                className="inputText"
                type="text"
                disabled={isReject === true}
              />
              <div className="listBtn btnLink" onClick={() => clickScopeLink(false, gitHub)}>
                Link
              </div>
            </div>
            <div className="row">
              <p className="inputDesc">Reddit</p>
              <input
                value={reddit}
                onChange={(e) => setReddit(e.target.value)}
                className="inputText"
                type="text"
                disabled={isReject === true}
              />
              <div className="listBtn btnLink" onClick={() => clickScopeLink(false, reddit)}>
                Link
              </div>
            </div>
            <div className="row">
              <p className="inputDesc">Twitter</p>
              <input
                value={twitter}
                onChange={(e) => setTwitter(e.target.value)}
                className="inputText"
                type="text"
                disabled={isReject === true}
              />
              <div className="listBtn btnLink" onClick={() => clickScopeLink(false, twitter)}>
                Link
              </div>
            </div>
            <div className="row">
              <p className="inputDesc">Discord</p>
              <input
                value={discord}
                onChange={(e) => setDiscord(e.target.value)}
                className="inputText"
                type="text"
                disabled={isReject === true}
              />
              <div className="listBtn btnLink" onClick={() => clickScopeLink(false, discord)}>
                Link
              </div>
            </div>
            <div className="row">
              <p className="inputDesc">Telegram</p>
              <input
                value={telegram}
                onChange={(e) => setTelegram(e.target.value)}
                className="inputText"
                type="text"
                disabled={isReject === true}
              />
              <div className="listBtn btnLink" onClick={() => clickScopeLink(false, telegram)}>
                Link
              </div>
            </div>
            <div className="row">
              <p className="inputDesc">Medium</p>
              <input
                value={medium}
                onChange={(e) => setMedium(e.target.value)}
                className="inputText"
                type="text"
                disabled={isReject === true}
              />
              <div className="listBtn btnLink" onClick={() => clickScopeLink(false, medium)}>
                Link
              </div>
            </div>
            <div className="row">
              <p className="inputDesc">Opensea</p>
              <input
                value={opensea}
                onChange={(e) => setOpensea(e.target.value)}
                className="inputText"
                type="text"
                disabled={isReject === true}
              />
              <div className="listBtn btnLink" onClick={() => clickScopeLink(false, opensea)}>
                Link
              </div>
            </div>
            <div className="row">
              <p className="inputDesc">Ticketing</p>
              <input
                value={ticketing}
                onChange={(e) => setTicketing(e.target.value)}
                className="inputText"
                type="text"
                disabled={isReject === true}
              />
              <div className="listBtn btnLink" onClick={() => clickScopeLink(false, ticketing)}>
                Link
              </div>
            </div>
            <div className="row">
              <p className="inputDesc">coinMarketCap</p>
              <input
                value={coinMarketCap}
                onChange={(e) => setCoinMarketCap(e.target.value)}
                className="inputText"
                type="text"
                disabled={isReject === true}
              />
              <div className="listBtn btnLink" onClick={() => clickScopeLink(false, coinMarketCap)}>
                Link
              </div>
            </div>
            <div className="row">
              <p className="inputDesc">CoinGecko</p>
              <input
                value={coinGecko}
                onChange={(e) => setCoinGecko(e.target.value)}
                className="inputText"
                type="text"
                disabled={isReject === true}
              />
              <div className="listBtn btnLink" onClick={() => clickScopeLink(false, coinGecko)}>
                Link
              </div>
            </div>

            <div className="buttonArea">
              {!isReject ? (
                <>
                  <div
                    className="listBtn btnClose"
                    onClick={() => confirmPopup('거절 하시겠습니까?', tokenDetailCancel)}
                  >
                    Reject
                  </div>
                  <div
                    className="listBtn btnSubmit"
                    onClick={() => confirmPopup('승인 하시겠습니까?', submitKCTVerification)}
                  >
                    Approval
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="loadingArea">
          <img className="loadingImg" src={loadingImg} alt="" />
        </div>
      )}
    </>
  );
}
