import axios from 'axios';
import { API_HOST_PREFIX } from '../constants/common';

const port = process.env.REACT_APP_API_PORT ? `:${process.env.REACT_APP_API_PORT}` : '';
const ORIGIN = process.env.REACT_APP_API_HOST
  ? process.env.REACT_APP_API_HOST
  : window.location.hostname;

const URL = process.env.API_SERVER_URL;
const API_VERSION = process.env.REACT_APP_API_VERSION || 'v1';
export const API_ENTRY = URL || `https://${ORIGIN}${port}/${API_VERSION}`;

export const RequestType = Object.freeze({
  GET: 'get',
  POST: 'post'
});

export const RequestPath = Object.freeze({
  Path: 'path'
});

export const request = async function (
  reqType: string,
  path: string,
  route: string,
  payload?: any,
  options?: any
) {
  try {
    // @ts-ignore
    let { data } = await api[reqType].call(
      this,
      path.concat(route),
      ...(reqType === RequestType.POST ? [payload, options || {}] : [options || {}])
    );

    if (!data) {
      throw Error('INVALID_RESPONSE');
    }

    if (!data.data || data.result === 'fail') {
      throw Error('FAILED_REQUEST');
    }

    return data;
  } catch (e) {
    throw e;
  }
};

export const api = axios.create({
  baseURL: API_ENTRY,
  responseType: 'json',

  headers: {
    'Content-Type': 'application/json'
  }

  // withCredentials: false
});

export const getAPI = async function (re: string) {
  return await fetch(`${API_HOST_PREFIX}/${re}`, { credentials: 'include' });
  //   return await fetch(`${API_HOST_PREFIX}/${re}`);
};

export const postAPI = async function (re: string, bodyParams: any) {
  const requestOptions: RequestInit = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: bodyParams
  };
  return await fetch(`${API_HOST_PREFIX}/${re}`, requestOptions);
};

export const postFormAPI = async function (re: string, bodyParams: any) {
  const requestOptions: RequestInit = {
    method: 'POST',
    credentials: 'include',
    body: bodyParams
  };
  return await fetch(`${API_HOST_PREFIX}/${re}`, requestOptions);
};
