import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import SearchResultPage from './pages/SearchResultPage';
import ListPage from './pages/ListPage';
import ListPageFail from './pages/ListPageFail';
import ListDetailPage from './pages/ListDetailPage';
import HistoryPage from './pages/HistoryPage';
import NotFoundPage from './pages/NotFoundPage';
import { inject, observer } from 'mobx-react';
import CommonHeader from './components/Common/CommonHeader';
import AlertPopup from './components/Common/AlertPopup';
import NftPage from './pages/NftPage';
import SyncFailedPage from './pages/SyncFailedPage';
import './styles/App.scss';
import PendingTokenPage from './pages/PendingTokenPage';
import PendingTokenDetailPage from './pages/PendingTokenDetailPage';
import SearchTokenPage from './pages/SearchTokenPage';

export default inject(({ store }) => ({
  popup: store.popupStore,
  stores: store
}))(
  observer((props: any) => {
    return (
      <>
        <CommonHeader />
        <div className="contentsArea">
          <Routes>
            <Route path="/" element={<LoginPage />} />
            {/* <Route path="/search" element={<SearchResultPage />} />
            <Route path="/search/:address" element={<SearchResultPage />} /> */}
            <Route path="/tokenList" element={<ListPage />} />
            <Route path="/tokenList/reject" element={<ListPageFail />} />
            <Route path="/tokenListDetail/:address" element={<ListDetailPage />} />
            <Route path="/tokenListDetail/:address/reject" element={<ListDetailPage />} />
            <Route path="/nft/list" element={<NftPage />} />
            <Route path="/nft/list/:address" element={<NftPage />} />
            <Route path="/nft/failedUri" element={<SyncFailedPage />} />
            <Route path="/nft/failedImageUri" element={<SyncFailedPage />} />

            <Route path="/history" element={<HistoryPage />} />
            <Route path="*" element={<NotFoundPage />} />
            <Route path="/pending-tokens" element={<PendingTokenPage />} />
            <Route path="/pending-tokens/:id" element={<PendingTokenDetailPage />} />
            <Route path="/search-tokens" element={<SearchTokenPage />} />
            {/* <Route
              path="/search-tokens?address=:address&chain=:chain&network=:network"
              element={<SearchTokenPage />}
            /> */}
          </Routes>
        </div>
        <AlertPopup />
      </>
    );
  })
);
