import { useNavigate, useParams } from 'react-router-dom';
import indexStore from '../mobx/store';
import './PendingTokenDetailPage';
import { useEffect, useState } from 'react';
import { getAPI, postAPI } from '../utils/api';
import { PendingTokenDetail } from '../types/pendingTokens';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { getQueryParamObj } from '../utils/util';

export default function PendingTokenDetailPage() {
  const urlParams = useParams();
  const { popupStore } = indexStore();
  const isLoading = popupStore.isLoading;
  const loadingImg = require('../images/loading.gif');

  // 상태 관리
  const [id, setId] = useState<number>(0);
  const [createdAt, setCreatedAt] = useState<string>('');
  const [chain, setChain] = useState<string>('');
  const [network, setNetwork] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [icon, setIcon] = useState<any>(null);
  const [mimeType, setMimeType] = useState<string>('');
  const [metadata, setMetadata] = useState<object>({});

  const [noImg] = useState(require('../images/noImg.jpg'));
  const [iconInfo, setIconInfo] = useState<any>([]);
  const [iconPreview, setIconPreview] = useState<any>(null);

  const navigator = useNavigate();
  useEffect(() => {
    getPendingTokenDetail();
  }, [urlParams.address]);

  async function getPendingTokenDetail() {
    popupStore.setIsLoading(true);

    const chain = getQueryParamObj(window.location.search)?.chain || '';
    const network = getQueryParamObj(window.location.search)?.network || '';

    let url = '';
    if (chain && network) {
      url = `pending-tokens/${urlParams.id}?chain=${chain}&network=${network}`;
    } else {
      url = `pending-tokens/${urlParams.id}`;
    }
    const response = await (await getAPI(url)).json();
    console.log(`response: `, response);
    if (response['error']) {
      alertPopup(`${response.error} (${response.statusCode})`);
      navigator(-1);
    }
    if (response['status'] === '1') {
      setPendingTokeDetail(response.result);
      popupStore.setIsLoading(false);
    } else {
      popupStore.setIsLoading(false);
    }
  }

  function ApprovePendingToken() {
    SubmitTokenApproval(true);
  }

  function RejectPendingToken() {
    SubmitTokenApproval(false);
  }

  async function SubmitTokenApproval(isApprove: boolean) {
    const bodyParams = {
      id: id
    };

    let response;
    if (isApprove) {
      response = await (await postAPI('pending-tokens/upload', JSON.stringify(bodyParams))).json();
    } else {
      response = await (await postAPI('pending-tokens/delete', JSON.stringify(bodyParams))).json();
    }

    if (response.status === '1') {
      alertPopup('처리 완료');
      navigator(-1);
    } else {
      alertPopup(`처리 실패, ${response.result}`);
    }
  }

  function confirmPopup(message: string, method?: Function) {
    popupStore.setConfirm(true);
    popupStore.setMessage(message);
    method ? popupStore.setMethod(method) : popupStore.setMethod(() => null);
    popupStore.setToggle(true);
  }

  function setPendingTokeDetail(result: PendingTokenDetail) {
    const { id, createdAt, chain, network, address, icon, mimeType, metadata } = result;

    setId(id);
    setCreatedAt(createdAt);
    setChain(chain);
    setNetwork(network);
    setAddress(address);

    if (icon) {
      let decodedFile = dataURLtoFile(`data:${mimeType};base64,${icon}`, 'icon');
      //   setIcon([decodedFile]);
      encodeFile(decodedFile);
      setIconInfo([{ name: decodedFile.name, size: decodedFile.size, type: decodedFile.type }]);
      setMimeType(mimeType);
    }
    setMetadata(metadata);
  }

  const dataURLtoFile = (dataUrl: any, fileName: string) => {
    var arr = dataUrl.split(','),
      mime = arr[0]?.match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], fileName, { type: mime });
  };

  function alertPopup(message: string) {
    popupStore.setConfirm(false);
    popupStore.setMessage(message);
    popupStore.setMethod(() => null);
    popupStore.setToggle(true);
  }

  function encodeFile(file: File, type?: string) {
    if (!file) {
      return setIconPreview(null);
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise<void>((resolve) => {
      reader.onload = () => {
        setIconPreview(reader.result);
        resolve();
      };
    });
  }

  return (
    <>
      {!isLoading ? (
        <div className="ListDetailPage">
          <div className="naming">
            <h3>Token Metadata</h3>
            <div className="row">
              <p className="inputDesc">ID</p>
              {id}
            </div>

            <div className="row">
              <p className="inputDesc">Token Address</p>
              {address}
            </div>

            <div className="row">
              <p className="inputDesc">Created At</p>
              {createdAt}
            </div>

            <div className="row">
              <p className="inputDesc">Chain</p>
              {chain}
            </div>

            <div className="row">
              <p className="inputDesc">Network</p>
              {network}
            </div>

            <div className="iconUpload">
              <p className="inputDesc top">Icon</p>
              <div className="iconPreview">
                <img alt={iconInfo[0]?.name} src={iconPreview ? iconPreview : noImg} />
              </div>
            </div>

            <div className="row">
              <p className="inputDesc">Icon MimeType</p>
              {mimeType}
            </div>

            <div className="row">
              <p className="inputDesc">Metadata</p>
              {metadata &&
                Object.entries(metadata)
                  .filter(([key, value]) => {
                    // value 가 있는 항목에 대해서만 노출
                    return !!value;
                  })
                  .map(([key, value]) => {
                    return (
                      <pre>
                        {key}: {value}
                      </pre>
                    );
                  })}
            </div>

            <div className="buttonArea">
              <div
                className="listBtn btnClose"
                onClick={() => confirmPopup('거절 하시겠습니까?', RejectPendingToken)}
              >
                Reject
              </div>
              <div
                className="listBtn btnSubmit"
                onClick={() => confirmPopup('승인 하시겠습니까?', ApprovePendingToken)}
              >
                Approval
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="loadingArea">
          <img className="loadingImg" src={loadingImg} alt="" />
        </div>
      )}
    </>
  );
}
