import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'mobx-react';
import './utils/index';
import './utils/DecimalCalc';
import './styles/index.scss';

import App from './App';
import store from './mobx';
// import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

if (
  !new (class {
    x: any;
  })().hasOwnProperty('x')
)
  throw new Error('Transpiler is not configured correctly');

const root = createRoot(document.getElementById('root')!);

root.render(
  <Provider store={store}>
    {/* <React.StrictMode> */}
    <BrowserRouter>
      <App />
    </BrowserRouter>
    {/* </React.StrictMode> */}
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
