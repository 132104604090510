import React, { useState } from 'react';
import './SyncAllButton.scss';
import { API_HOST_PREFIX } from '../constants/common';
const loadingImg = require('../images/loading.gif');

interface Props {
  menuName?: String;
  btnType?: String;
  tokenAddress: any;
  alertPopup: Function;
  statusKeyword?: String;
  refresh: Function;
}
export default function SyncAllButton(props: Props) {
  const { btnType, menuName, tokenAddress, alertPopup, statusKeyword, refresh } = props;

  const [btnState, setbtnState] = useState('');

  async function syncSubmitAction() {
    if (btnState !== '') return;
    setbtnState('Loading');
    let obj = {};
    let URL = '';

    if (btnType === 'Address') {
      obj = {
        tokenAddress
      };
      URL = `${API_HOST_PREFIX}/metadata/${
        menuName === 'failedUri' ? 'failedUri' : 'failedImageUri'
      }`;
    } else if (btnType === 'Status') {
      obj = {
        status: statusKeyword
      };
      URL = `${API_HOST_PREFIX}/metadata/${
        menuName === 'failedUri' ? 'failedUri' : 'failedImageUri'
      }`;
    } else {
      obj = {
        tokenId: ''
      };
      URL = `${API_HOST_PREFIX}/metadata/${tokenAddress}/sync`;
    }

    await fetch(URL, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(obj)
    })
      .then((r) => r.json())
      .then((json) => {
        if (json.success === true) {
          setbtnState('Success');
        } else {
          setbtnState('Fail');
          alertPopup(json.message);
        }
      })
      .catch((e) => {
        console.warn(e);
        alertPopup(e.message);
      })
      .finally(() => {
        setTimeout(() => {
          setbtnState('');
          refresh();
        }, 3000);
      });
  }

  return (
    <button className="btnSyncAll" onClick={() => syncSubmitAction()}>
      {btnState === '' ? (
        'Sync All'
      ) : btnState === 'Loading' ? (
        <img className="loadingImg" src={loadingImg} alt="" />
      ) : btnState === 'Success' ? (
        <span className="success">Success</span>
      ) : (
        <span className="fail">Fail</span>
      )}
    </button>
  );
}
