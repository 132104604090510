import { isEmpty } from 'lodash';
import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { getQueryParamObj } from '../../utils/util';

export default function NftSubTab() {
  const navigate = useNavigate();
  const params = useParams();

  function tabClickHandler(sub: string) {
    const search = window.location.search;
    const address = getQueryParamObj(search).tokenAddress || params.address || null;
    if (sub === 'nft') {
      navigate(isEmpty(address) ? `/nft/list` : `/nft/list/${address}`);
    } else if (sub === 'failedUri') {
      navigate(isEmpty(address) ? `/nft/failedUri` : `/nft/failedUri?tokenAddress=${address}`);
    } else {
      navigate(
        isEmpty(address) ? `/nft/failedImageUri` : `/nft/failedImageUri?tokenAddress=${address}`
      );
    }
  }
  return (
    <>
      <button
        className={`listSubTab ${
          window.location.pathname === `/nft/list/${params.address}` ||
          window.location.pathname === `/nft/list`
            ? 'on'
            : ''
        }`}
        onClick={() => tabClickHandler('nft')}
      >
        NFT
      </button>
      <button
        className={`listSubTab ${
          window.location.pathname === `/nft/failedUri?tokenAddress=${params.address}` ||
          window.location.pathname === `/nft/failedUri`
            ? 'on'
            : ''
        }`}
        onClick={() => tabClickHandler('failedUri')}
      >
        Failed List
      </button>
      <button
        className={`listSubTab ${
          window.location.pathname === `/nft/failedImageUri?tokenAddress=${params.address}` ||
          window.location.pathname === `/nft/failedImageUri`
            ? 'on'
            : ''
        }`}
        onClick={() => tabClickHandler('failedImageUri')}
      >
        Failed List(Img)
      </button>
    </>
  );
}
