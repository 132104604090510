import React from 'react';
import './Paginator.scss';
import Pagination from '@mui/material/Pagination';
export default function Paginator(props: any) {
  const { goTo, maxPage, page } = props;
  function handleChangePage(e: React.ChangeEvent<unknown>, page: number) {
    goTo(page);
  }

  return (
    <div className="PaginatorArea">
      <Pagination
        count={Number(maxPage)}
        color="primary"
        showFirstButton
        showLastButton
        page={Number(page)}
        onChange={handleChangePage}
      />
    </div>
  );
}
