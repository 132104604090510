import React, { useEffect, useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import './BtnCopy.scss';

interface Props {
  tokenId: string;
}
export default function BtnCopy({ tokenId }: Props) {
  const [isCopied, setisCopied] = useState(false);

  let timer: any;
  useEffect(() => {
    return () => {
      clearTimeout(timer);
    };
  }, []);

  function clickCopyBtnHandler() {
    setisCopied(true);
    copyText(tokenId);
    timer = setTimeout(() => {
      setisCopied(false);
      clearTimeout(timer);
    }, 1000);
  }

  function copyText(text: any) {
    if (!document.queryCommandSupported('copy')) {
      return alert('This browser does not support copying.');
    }

    const textarea = document.createElement('textarea');
    textarea.value = text;
    textarea.style.top = '0';
    textarea.style.left = '0';
    textarea.style.position = 'fixed';

    document.body.appendChild(textarea);
    textarea.focus();
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  }

  return (
    <span className="copyButtonArea" onClick={() => clickCopyBtnHandler()}>
      <span className="tokenId">{tokenId}</span>
      {isCopied ? (
        <span className="btnCopy">
          <CheckIcon className="check" />
        </span>
      ) : (
        <span className="btnCopy" onClick={() => clickCopyBtnHandler()}>
          <ContentCopyIcon />
        </span>
      )}
    </span>
  );
}
