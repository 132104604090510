import React, { useEffect, useState } from 'react';
import './NftPage.scss';
import indexStore from '../mobx/store';
import { useNavigate, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { addPrefixToImageUrl } from '../utils/util';
import Paginator from '../components/Common/Paginator';
import SyncButton from '../components/SyncButton';
import TokenIdSearch from '../components/TokenIdSearch';
import BtnCopy from '../components/Common/BtnCopy';
import NftSubTab from '../components/Common/NftSubTab';
import NftSearchInput from '../components/Common/NftSearchInput';
import SyncAllButton from '../components/SyncAllButton';
import { API_HOST_PREFIX } from '../constants/common';

export default function NftPage() {
  const navigate = useNavigate();
  const params = useParams();
  const { popupStore } = indexStore();

  const isLoading = popupStore.isLoading;
  const loadingImg = require('../images/loading.gif');
  const [noImg] = useState(require('../images/noImg.jpg'));

  const [searchKeyword, setSearchKeyword] = useState<String | any>('');
  const [whitelistToken, setWhitelistToken] = useState<String>('');
  const [inventoryList, setinventoryList] = useState<any>([]);

  const [checkedList, setcheckedList] = useState<any>([]);

  const [currentPage, setcurrentPage] = useState<any>(0);
  const [maxPage, setmaxPage] = useState<any>(0);

  useEffect(() => {
    const search = window.location.search;
    if (params.address) {
      setSearchKeyword(params.address);
      getSearchResult(search);
    }
  }, [params.address]);

  function inputHandler(keyword: String) {
    setSearchKeyword(keyword);
  }

  function handleKeyPress(e: any) {
    if (e.key === 'Enter') {
      searchHandler();
    }
  }

  function searchHandler() {
    if (params.address === searchKeyword.trim()) {
      getSearchResult();
    } else {
      navigate(`/nft/list/${searchKeyword}`);
    }
  }

  async function getSearchResult(search?: string) {
    popupStore.setIsLoading(true);
    const URL = `${API_HOST_PREFIX}/metadata/${params.address}${
      search ? search + '&limit=20' : '?limit=20'
    }`;
    await fetch(URL, { credentials: 'include' })
      .then((res) => res.json())
      .then((json) => {
        if (json.code === 20000) {
          alertPopup(json.message);
        } else {
          setcurrentPage(json.page);
          setmaxPage(Math.ceil(json.total / json.limit));
          setinventoryList(json.result);
          initCheckBox(json.result);
        }
      })
      .catch((e) => {
        console.warn(e);
      })
      .finally(() => {
        popupStore.setIsLoading(false);
      });
  }

  function initCheckBox(list: any) {
    let arr: any = [];
    list.map((r: any) => {
      return r.whitelistFlag === true && arr.push(r.tokenId);
    });
    setcheckedList(arr);
    arr = [];
  }

  function imgParser(list: any) {
    const { imageMimetype, thumbnail } = list;

    if (!imageMimetype || !thumbnail) return noImg;

    return addPrefixToImageUrl(thumbnail, imageMimetype);
  }

  function alertPopup(message: string) {
    popupStore.setConfirm(false);
    popupStore.setMessage(message);
    popupStore.setMethod(() => null);
    popupStore.setToggle(true);
  }

  function errorImg(e: any) {
    e.target.src = noImg;
  }

  function checkBoxEvent(e: any) {
    let val = e.target.value;
    let flag = true;
    let arr = [...checkedList];

    if (arr.includes(val)) {
      flag = false;
    } else {
      flag = true;
    }

    submitResult(val, flag).then((r) => {
      if (r.success === true) {
        if (arr.includes(val)) {
          let idx = arr.findIndex((r: any) => r === val);
          arr.splice(idx, 1);
        } else {
          arr.push(val);
        }
        setcheckedList(arr);
      } else {
        alertPopup(r.message);
      }
    });
  }

  async function submitResult(tokenId: any, whitelistFlag: boolean) {
    let result = {
      success: false,
      message: 'Error'
    };
    const obj = {
      tokenId,
      whitelistFlag
    };
    const URL = `${API_HOST_PREFIX}/metadata/${params.address}`;

    await fetch(URL, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(obj)
    })
      .then((r) => r.json())
      .then((json) => {
        if (json.success === true) {
          console.log('success');
          result = json;
        } else {
          console.log('fail');
          result = json;
        }
      })
      .catch((e) => {
        console.warn(e);
      });

    return result;
  }

  async function submitResultAll(whitelistFlag: boolean) {
    let result = {
      success: false,
      message: 'Error'
    };
    const obj = {
      whitelistFlag
    };
    const URL = `${API_HOST_PREFIX}/metadata/${params.address}`;
    await fetch(URL, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(obj)
    })
      .then((r) => r.json())
      .then((json) => {
        if (json.success === true) {
          console.log('success');
          result = json;
        } else {
          console.log('fail');
          result = json;
        }
      })
      .catch((e) => {
        console.warn(e);
      });

    return result;
  }

  function checkAllEvent(e: any) {
    submitResultAll(e.target.value === 'true' ? true : false).then((r) => {
      if (r.success === true) {
        setWhitelistToken(e.target.value);
        if (e.target.value === 'false') {
          setcheckedList([]);
        } else {
          let arr = inventoryList.map((r: any) => r.tokenId);
          setcheckedList(arr);
        }
      } else {
        alertPopup(r.message);
      }
    });
  }

  function goTo(page: number) {
    const search = `?page=${page}`;
    navigate(`/nft/list/${params.address}${search}`);
    setcurrentPage(page);
    getSearchResult(search);
  }

  function refresh() {
    console.log('refresh');
  }

  return (
    <>
      <NftSubTab />
      <div className="NftPage">
        <div className="SearchResultPage">
          <NftSearchInput
            searchKeyword={searchKeyword}
            inputHandler={inputHandler}
            handleKeyPress={handleKeyPress}
            searchHandler={searchHandler}
          />

          <>
            {isLoading && (
              <div className="loadingArea">
                <img className="loadingImg" src={loadingImg} alt="" />
              </div>
            )}
          </>

          {!isLoading && params.address && (
            <div className="searchResult">
              <div className="tokenMatch">
                {/* <h3>NFT Metadata</h3> */}
                <div>
                  <p className="inputDesc">Whitelist ALL</p>
                  <div className="input_radioArea">
                    <p>
                      <input
                        id="opt7"
                        type="radio"
                        value={'false'}
                        name="opt7"
                        checked={whitelistToken === 'false'}
                        onChange={(e) => {
                          checkAllEvent(e);
                        }}
                      />
                      <label htmlFor="opt7">False</label>
                    </p>
                    <p>
                      <input
                        id="opt8"
                        type="radio"
                        value={'true'}
                        name="opt8"
                        checked={whitelistToken === 'true'}
                        onChange={(e) => {
                          checkAllEvent(e);
                        }}
                      />
                      <label htmlFor="opt8">True</label>
                    </p>
                  </div>

                  <TokenIdSearch getSearchResult={getSearchResult} />
                </div>
                <div>
                  <p className="inputDesc">Sync ALL</p>
                  <div className="input_radioArea">
                    <SyncAllButton
                      tokenAddress={params.address}
                      alertPopup={alertPopup}
                      refresh={refresh}
                    />
                  </div>
                </div>
              </div>

              {!isEmpty(inventoryList) && !isLoading && (
                <div className="nftInventoryArea">
                  <ul className="inventoryul">
                    {inventoryList.map((e: any, i: number) => {
                      return (
                        <li key={i} className="nftList">
                          <div className="imgArea">
                            <img src={imgParser(e)} alt={e.tokenId} onError={(e) => errorImg(e)} />
                          </div>

                          <div className="desc">
                            <p>
                              <span className="key">Token ID</span>{' '}
                              <span className="val">
                                <BtnCopy tokenId={e.tokenId} />
                              </span>
                            </p>
                            <p>
                              <span className="key"> WhiteList</span>
                              <label className="val">
                                <input
                                  type="checkbox"
                                  value={e.tokenId}
                                  checked={checkedList.includes(e.tokenId)}
                                  onChange={(e) => checkBoxEvent(e)}
                                />
                                <span>{checkedList.includes(e.tokenId) ? 'True' : 'False'}</span>
                              </label>
                            </p>
                            <p>
                              <span className="key">Sync</span>{' '}
                              <span className="val">
                                <SyncButton
                                  syncType={'nft'}
                                  tokenAddress={params.address || ''}
                                  tokenId={e.tokenId}
                                  alertPopup={alertPopup}
                                />
                              </span>
                            </p>
                          </div>
                        </li>
                      );
                    })}
                  </ul>

                  <div className="pagination">
                    <Paginator goTo={goTo} page={currentPage} maxPage={maxPage} />
                  </div>
                </div>
              )}

              {isEmpty(inventoryList) && !isLoading && <div className="noData">NO Data</div>}
              <h3> </h3>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
