/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './ListPage.scss';
import ListAltIcon from '@mui/icons-material/ListAlt';
import indexStore from '../mobx/store';
import { API_HOST_PREFIX } from '../constants/common';

const { popupStore } = indexStore();

const loadingImg = require('../images/loading.gif');

export default function ListPageFail() {
  const [tokenList, setTokenList] = useState<any>([]);

  const navigation = useNavigate();
  const isLoading = popupStore.isLoading;
  useEffect(() => {
    getTokenList();
  }, []);

  function listClickHandler(url: string) {
    navigation(`${url}/reject`);
  }

  async function getTokenList() {
    popupStore.setIsLoading(true);
    const URL = `${API_HOST_PREFIX}/tokenList?type=fail`;
    await fetch(URL, { credentials: 'include' })
      .then((res) => res.json())
      .then((json) => {
        if (json.result) {
          setTokenList(json.result);
          popupStore.setIsLoading(false);
        }
      })
      .catch((e) => {
        console.warn(e);
        popupStore.setIsLoading(false);
      });
  }

  function iconToBase64(icon: string | null | any, mimeType: string | null) {
    return `data:${mimeType};base64,${icon}`;
  }

  return (
    <>
      <button
        className={`listSubTab ${window.location.pathname === '/tokenList' ? 'on' : ''}`}
        onClick={() => navigation('/tokenList')}
      >
        Pending
      </button>
      <button
        className={`listSubTab ${window.location.pathname === '/tokenList/reject' ? 'on' : ''}`}
        onClick={() => navigation('/tokenList/reject')}
      >
        Rejected
      </button>
      {!isLoading ? (
        <div className="ListPage">
          <ul>
            {tokenList.map((e: any) => {
              return (
                <li key={e.id} className="list">
                  <div className="listAddress">
                    <a onClick={() => listClickHandler(`/tokenListDetail/${e.id}`)}>
                      {e.icon ? (
                        <img className="iconImg" src={iconToBase64(e.icon, e.iconMimeType)} />
                      ) : (
                        <div className="iconImg noImg"></div>
                      )}
                      <span>{e.tokenAddress}</span>
                    </a>
                  </div>

                  <div
                    className="listBtn btnDetail"
                    onClick={() => listClickHandler(`/tokenListDetail/${e.id}`)}
                  >
                    <ListAltIcon />
                    <span>Detail</span>
                  </div>
                </li>
              );
            })}
            {tokenList.length === 0 && (
              <li className="list">
                <div className="listAddress full">
                  <span className="noResult">No Data</span>
                </div>
              </li>
            )}
          </ul>
        </div>
      ) : (
        <div className="loadingArea">
          <img className="loadingImg" src={loadingImg} alt="" />
        </div>
      )}
    </>
  );
}
