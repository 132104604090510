import { observable } from 'mobx';

const popupStore = observable({
  isConfirm: true,
  toggle: false,
  message: '',
  isLoading: false,
  method: () => null,

  setConfirm(bool: boolean) {
    this.isConfirm = bool;
  },
  setToggle(bool: boolean) {
    this.toggle = bool;
  },
  setMessage(message: string) {
    this.message = message;
  },
  setMethod(fn: Function) {
    this.method = fn;
  },
  setIsLoading(bool: boolean) {
    this.isLoading = bool;
  }
});

export default popupStore;
