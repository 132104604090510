import React, { useState } from 'react';
import { API_HOST_PREFIX } from '../constants/common';
const loadingImg = require('../images/loading.gif');

interface Props {
  syncType: string;
  tokenAddress?: string;
  tokenId: any;
  alertPopup: Function;
  refresh?: Function;
}

export default function SyncButton(props: Props) {
  const { syncType, tokenAddress, tokenId, alertPopup, refresh } = props;

  const [btnState, setbtnState] = useState('');

  async function syncSubmitAction() {
    if (btnState !== '') return;
    setbtnState('Loading');
    let obj = {};
    let URL = ``;

    if (syncType === 'nft') {
      obj = { tokenId };
      URL = `${API_HOST_PREFIX}/metadata/${tokenAddress}/sync`;
    } else if (syncType === 'failedUri') {
      obj = { tokenAddress, tokenId };
      URL = `${API_HOST_PREFIX}/metadata/failedUri`;
    } else if (syncType === 'failedImageUri') {
      obj = { tokenAddress, tokenId };
      URL = `${API_HOST_PREFIX}/metadata/failedImageUri`;
    }

    await fetch(URL, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(obj)
    })
      .then((r) => r.json())
      .then((json) => {
        if (json.success === true) {
          setbtnState('Success');
        } else {
          setbtnState('Fail');
          alertPopup(json.message);
        }
      })
      .catch((e) => {
        console.warn(e);
        alertPopup(e.message);
      })
      .finally(() => {
        setTimeout(() => {
          setbtnState('');
          if (refresh) {
            refresh();
          }
        }, 3000);
      });
  }

  return (
    <button className="btnSync" onClick={() => syncSubmitAction()}>
      {btnState === '' ? (
        'Sync'
      ) : btnState === 'Loading' ? (
        <img className="loadingImg" src={loadingImg} alt="" />
      ) : btnState === 'Success' ? (
        <span className="success">Success</span>
      ) : (
        <span className="fail">Fail</span>
      )}
    </button>
  );
}
