import { useObserver } from 'mobx-react';
import indexStore from '../../mobx/store';
import './AlertPopup.scss';

export default function AlertPopup() {
  const { popupStore } = indexStore();

  function popupCancelHandler() {
    popupStore.setToggle(false);
  }
  function popupSubmitHandler() {
    popupStore.setToggle(false);
    popupStore.method();
  }

  return useObserver(() => (
    <>
      {popupStore.toggle && (
        <div className="blackBack">
          <div className="popupArea">
            <div className="popupIcon"></div>
            <div className="popupMsg">
              <h2>{popupStore.message || ''}</h2>
            </div>
            <div className="popupBtnArea">
              {popupStore.isConfirm === true ? (
                <>
                  <button className="btnPopup btnCancel" onClick={() => popupCancelHandler()}>
                    취소
                  </button>
                  <button className="btnPopup btnSubmit" onClick={() => popupSubmitHandler()}>
                    확인
                  </button>
                </>
              ) : (
                <button className="btnPopup btnSubmit full" onClick={() => popupCancelHandler()}>
                  확인
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  ));
}
