import React, { useEffect, useState } from 'react';
import './SyncFailedPage.scss';
import { useNavigate, useParams } from 'react-router-dom';
import indexStore from '../mobx/store';
import SyncFailedTable from '../components/SyncFailedTable';
import NftSubTab from '../components/Common/NftSubTab';
import NftSearchInput from '../components/Common/NftSearchInput';
import { getQueryParamObj, getQueryParamString } from '../utils/util';
import { isEmpty } from 'lodash';
import { API_HOST_PREFIX } from '../constants/common';

export default function SyncFailedPage() {
  const navigate = useNavigate();
  const params = useParams();
  const { popupStore } = indexStore();
  const isLoading = popupStore.isLoading;
  const [searchKeyword, setSearchKeyword] = useState<String | any>('');
  const [currentPage, setcurrentPage] = useState<any>(1);
  const [maxPage, setmaxPage] = useState<any>(1);
  const [failedList, setfailedList] = useState<Array<any>>([]);
  const [searchTypeList] = useState<Array<string>>(['Address', 'Status']);
  const [searchType, setsearchType] = useState<string>('Address');

  useEffect(() => {
    const searchParam = getQueryParamObj(window.location.search);
    let type = !isEmpty(searchParam.status) ? 'Status' : 'Address';
    setsearchType(type);
    let keyword = !isEmpty(searchParam.status) ? searchParam.status : searchParam.tokenAddress;
    setSearchKeyword(keyword || '');
    let search = getQueryParamString(searchParam);
    getSearchResult(search);
  }, [params]);

  function inputHandler(keyword: String) {
    setSearchKeyword(keyword);
  }

  function handleKeyPress(e: any) {
    if (e.key === 'Enter') {
      searchHandler();
    }
  }

  function searchHandler() {
    let search = '';
    if (searchType === 'Status') search = `?status=${searchKeyword.trim()}`;
    if (searchType === 'Address') search = `?tokenAddress=${searchKeyword.trim()}`;

    if (params.address === searchKeyword.trim()) {
      return getSearchResult(search);
    } else {
      if (searchType === 'Status') {
        navigate(
          `/nft/${
            window.location.pathname === '/nft/failedImageUri' ? 'failedImageUri' : 'failedUri'
          }?status=${searchKeyword.trim()}`
        );
        getSearchResult(search);
      } else {
        navigate(
          `/nft/${
            window.location.pathname === '/nft/failedImageUri' ? 'failedImageUri' : 'failedUri'
          }${searchKeyword ? `?tokenAddress=${searchKeyword}` : ''}`
        );
      }
    }
  }
  async function getSearchResult(search?: string) {
    popupStore.setIsLoading(true);
    const URL = `${API_HOST_PREFIX}/metadata/${
      window.location.pathname === '/nft/failedImageUri' ? 'failedImageUri' : 'failedUri'
    }${search}`;
    await fetch(URL, { credentials: 'include' })
      .then((res) => res.json())
      .then((json) => {
        if (json.code === 20000) {
          alertPopup(json.message);
        } else {
          setcurrentPage(json.page);
          setmaxPage(Math.ceil(json.total / json.limit));
          setfailedList(json.result || []);
        }
      })
      .catch((e) => {
        console.warn(e);
      })
      .finally(() => {
        popupStore.setIsLoading(false);
      });
  }

  function alertPopup(message: string) {
    popupStore.setConfirm(false);
    popupStore.setMessage(message);
    popupStore.setMethod(() => null);
    popupStore.setToggle(true);
  }

  function goTo(page: number) {
    let prevSearchObj = getQueryParamObj(window.location.search);
    prevSearchObj.page = page;
    const search = getQueryParamString(prevSearchObj);
    navigate(
      `/nft/${
        window.location.pathname === '/nft/failedImageUri' ? 'failedImageUri' : 'failedUri'
      }${search}`
    );
    getSearchResult(search);
    setcurrentPage(page);
  }

  function searchTypeHanlder(type: any) {
    setsearchType(type);
  }

  function filterSubmitHanlder(
    selectedFilter: string,
    tokenIdValue: string,
    fromTokenId: string,
    toTokenId: string
  ) {
    let searchObj = getQueryParamObj(window.location.search);
    searchObj.page = 1;
    if (selectedFilter === 'TokenID') {
      searchObj.tokenId = tokenIdValue;
    } else {
      if (!fromTokenId) {
        return alertPopup(`'From' 값을 입력 해주세요.`);
      } else if (!toTokenId) {
        return alertPopup(`'To' 값을 입력 해주세요.`);
      } else if (Number(fromTokenId) > Number(toTokenId)) {
        return alertPopup(`'From' 이 'To' 보다 작아야 합니다.`);
      }
      searchObj.fromTokenId = fromTokenId;
      searchObj.toTokenId = toTokenId;
    }
    navigate(
      `/nft/${
        window.location.pathname === '/nft/failedImageUri' ? 'failedImageUri' : 'failedUri'
      }${getQueryParamString(searchObj)}`
    );
    getSearchResult(getQueryParamString(searchObj));
  }

  function refresh() {
    console.log('refresh');
    getSearchResult(window.location.search);
  }

  return (
    <>
      <NftSubTab />
      <div className="SyncFiledPage">
        <div className="SearchResultPage">
          <NftSearchInput
            searchKeyword={searchKeyword}
            inputHandler={inputHandler}
            handleKeyPress={handleKeyPress}
            searchHandler={searchHandler}
            searchTypeList={searchTypeList}
            searchType={searchType}
            searchTypeHanlder={searchTypeHanlder}
          />

          <SyncFailedTable
            goTo={goTo}
            failedList={failedList}
            currentPage={currentPage}
            maxPage={maxPage}
            isLoading={isLoading}
            alertPopup={alertPopup}
            searchType={searchType}
            filterSubmitHanlder={filterSubmitHanlder}
            menuName={
              window.location.pathname === '/nft/failedImageUri' ? 'failedImageUri' : 'failedUri'
            }
            refresh={refresh}
          />
        </div>
      </div>
    </>
  );
}
