import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';

import './CommonHeader.scss';
import SettingPopup from './SettingPopup';
import popupStore from '../../mobx/store/popup';
import { API_HOST_PREFIX, IS_DEV } from '../../constants/common';
import { getAPI } from '../../utils/api';

interface StatusResponse {
  status: string;
  message: string;
  result: string;
}

export default function CommonHeader() {
  const [tabList] = useState([
    // { key: 1, name: 'Token List', path: '/tokenList', class: 'tokenList' },
    // { key: 2, name: 'Search', path: '/search', class: 'search' },
    // { key: 3, name: 'NFT Metadata', path: '/nft/list', class: 'nft' },
    // { key: 4, name: 'History', path: '/history', class: 'history' },
    {
      key: 1,
      name: 'Pending Tokens',
      path: '/pending-tokens',
      class: 'pending-tokens'
    },
    { key: 2, name: 'Search Tokens', path: '/search-tokens', class: 'search-tokens' }
  ]);

  const [settingToggle, setSettingToggle] = useState(false);
  const [user, setUser] = useState('Unknown');
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [role, setRole] = useState('');
  const pathname = window.location.pathname;

  const location = useLocation();
  const navigate = useNavigate();

  function closePopupHandler() {
    setSettingToggle(false);
  }

  useEffect(() => {
    if (location.pathname === '/') return;
    if (sessionStorage.uid) {
      setUser(sessionStorage.uid);
      setIsSuperAdmin(sessionStorage.sa);
      setRole(sessionStorage.role);
    } else {
      loginCheck();
    }
  }, [location]);

  function confirmPopup(message: string, method?: Function) {
    popupStore.setConfirm(true);
    popupStore.setMessage(message);
    method ? popupStore.setMethod(method) : popupStore.setMethod(() => null);
    popupStore.setToggle(true);
  }

  async function loginCheck() {
    if (IS_DEV) return;
    const mainPage = location.pathname === '/' ? true : false;
    const response: StatusResponse = await (await getAPI('status')).json();
    if (response.status === '1' && response.result) {
      sessionStorage.uid = response.result;
      setUser(response.result);
    } else {
      sessionStorage.removeItem('uid');
      setUser('Unknown');

      if (mainPage) return;
      navigate('/');
    }
  }

  function logoutHandler() {
    confirmPopup('로그아웃 하시겠습니까?', () => logout());
  }
  async function logout() {
    const URL = `${API_HOST_PREFIX}/logout`;
    sessionStorage.removeItem('uid');
    sessionStorage.removeItem('sa');
    sessionStorage.removeItem('role');

    window.location.href = URL;
  }

  return (
    <div className="HeaderArea">
      {location.pathname !== '/' && (
        <>
          <div className="user">
            <div className="profile">
              <AccountCircleIcon className="userIcon" />
              <span>{user || 'Guest'}</span>
            </div>
            {<LogoutIcon className="logoutIcon" onClick={() => logoutHandler()} />}
            {isSuperAdmin && (
              <SettingsIcon
                className="settingIcon"
                onClick={() => setSettingToggle(!settingToggle)}
              />
            )}
          </div>
          <ul>
            {pathname !== '/' && (
              <>
                {tabList.map((e) => {
                  return (
                    <li
                      className={`menuList ${pathname.includes(e.class) ? 'on' : ''}`}
                      key={e.key}
                    >
                      <Link to={e.path}>{e.name}</Link>
                    </li>
                  );
                })}
              </>
            )}
          </ul>
          <SettingPopup
            settingToggle={settingToggle}
            closePopupHandler={() => closePopupHandler()}
          />
        </>
      )}
    </div>
  );
}
