import { useEffect, useState } from 'react';
import Paginator from '../components/Common/Paginator';
import DownloadIcon from '@mui/icons-material/Download';
import './HistoryPage.scss';
import DownloadPopup from '../components/Common/DownloadPopup';
import { API_HOST_PREFIX } from '../constants/common';

export default function HistoryPage() {
  interface historyListType {
    email: string;
    ip: string;
    before: string;
    after: string;
    method: string;
    status: number;
    url: string;
    createdAt: string;
  }

  const [historyList, sethistoryList] = useState([]);
  const [page, setpage] = useState(sessionStorage.page ? JSON.parse(sessionStorage.page) : 1);
  const [maxPage, setmaxPage] = useState(1);
  const [downloadPopup, setdownloadPopup] = useState(false);

  useEffect(() => {
    getHistory(`?page=${page}&limit=25`);
  }, []);

  useEffect(() => {
    sessionStorage.page = page;
  }, [page]);

  async function getHistory(params?: string) {
    const URL = `${API_HOST_PREFIX}/logs${params}`;
    await fetch(URL, {
      credentials: 'include'
    })
      .then((r) => r.json())
      .then((json) => {
        if (json.success === true) {
          sethistoryList(json.result);
          setmaxPage(calcMaxPage(json.limit, json.total));
        }
      });
  }

  function goTo(page: number) {
    setpage(page);
    getHistory(`?page=${page}&limit=25`);
  }

  function calcMaxPage(limit: number, total: number) {
    let result = Math.ceil(total / limit);
    return result >= 2000 ? 2000 : result;
  }

  function beforeAfterDetail(params: string) {
    return params ? (
      <div onClick={() => openDetail(params)} className="btnDetail">
        Detail
      </div>
    ) : (
      <></>
    );
  }

  function openDetail(params: string) {
    window.open('', 'PopupWin')?.document.write(`<div>${params}</div>`);
  }

  return (
    <div className="HistoryPage">
      {/* contents start */}

      {/* table area */}
      <div className="historyTable">
        <ul>
          <li className="historyList th">
            <div className="historyItem th email">Admin ID</div>
            <div className="historyItem th ip">IP</div>
            <div className="historyItem th before">Before</div>
            <div className="historyItem th after">After</div>
            <div className="historyItem th method">Method</div>
            <div className="historyItem th status">Status</div>
            <div className="historyItem th url">URL</div>
            <div className="historyItem th createdAt">Created</div>
          </li>

          {historyList.map((e: historyListType, i) => {
            return (
              <li key={i} className="historyList td">
                <div className="historyItem td email">{e.email}</div>
                <div className="historyItem td ip">{e.ip}</div>
                <div className="historyItem td before">{beforeAfterDetail(e.before)}</div>
                <div className="historyItem td after">{beforeAfterDetail(e.after)}</div>
                <div className="historyItem td method">{e.method}</div>
                <div className="historyItem td status">{e.status}</div>
                <div className="historyItem td url">
                  <button className="urllink" onClick={() => openDetail(e.url)}>
                    {e.url}
                  </button>
                </div>
                <div className="historyItem td createdAt">{e.createdAt}</div>
              </li>
            );
          })}
        </ul>
      </div>

      <div className="downloadArea">
        <div className="btnDownload" onClick={() => setdownloadPopup(!downloadPopup)}>
          [ Download <span>CSV Export</span>
          <DownloadIcon />]
        </div>
      </div>

      <Paginator goTo={goTo} page={page} maxPage={maxPage} />

      <DownloadPopup toggle={downloadPopup} closePopup={() => setdownloadPopup(!downloadPopup)} />
      {/* contents end */}
    </div>
  );
}
