import './LoginPage.scss';
import { API_HOST_PREFIX } from '../constants/common';

export default function LoginPage() {
  async function loginAction() {
    const URL = `${API_HOST_PREFIX}/login`;
    window.location.href = URL;
  }

  return (
    <div className="LoginPage">
      <div className="loginBox">
        <h2>Token Manager Tool</h2>
        <button className="btnLogin" onClick={() => loginAction()}></button>
      </div>
    </div>
  );
}
