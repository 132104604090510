import React, { useEffect, useState } from 'react';

import Paginator from '../components/Common/Paginator';
import './PendingTokenPage.scss';
import { getAPI } from '../utils/api';
import { Link, useNavigate } from 'react-router-dom';
import indexStore from '../mobx/store';
import { PendingToken } from '../types/pendingTokens';
import { SupportedChainAndNetwork } from '../types/searchToken';
import { API_HOST_PREFIX } from '../constants/common';

const { popupStore } = indexStore();
const loadingImg = require('../images/loading.gif');

export default function PendingTokenPage() {
  const [pendingTokenList, setPendingTokenList] = useState([]);

  const [chain, setChain] = useState<string>('');
  const [network, setNetwork] = useState<string>('');
  const [searchAddress, setSearchAddress] = useState<String | any>('');

  // 서버에서 받아올 지원하는 chain, network 목록
  const [chainList, setChainList] = useState<string[]>([]);
  const [networkList, setNetworkList] = useState<string[]>([]);

  const isLoading = popupStore.isLoading;
  const navigate = useNavigate(); // useNavigate 훅을 사용하여 네비게이션을 설정

  const [page, setPage] = useState(sessionStorage.page ? JSON.parse(sessionStorage.page) : 1);
  const [maxPage, setMaxPage] = useState(1);

  useEffect(() => {
    if (chainList.length === 0 && networkList.length === 0) {
      getChainAndNetwork();
    }
    getPendingTokenList(`?page=${page}&limit=25`);
  }, []);

  function alertPopup(message: string) {
    popupStore.setConfirm(false);
    popupStore.setMessage(message);
    popupStore.setMethod(() => null);
    popupStore.setToggle(true);
  }

  async function getChainAndNetwork() {
    const response = await (await getAPI('chains-and-networks')).json();
    setChainAndNetwork(response.result);
  }

  function goTo(page: number) {
    setPage(page);
    getPendingTokenList(`?page=${page}&limit=25`);
  }

  function calcMaxPage(limit: number, total: number) {
    let result = Math.ceil(total / limit);
    return result >= 2000 ? 2000 : result;
  }

  function setChainAndNetwork(result: SupportedChainAndNetwork) {
    setChainList(result.chains);
    setNetworkList(result.networks);
    setChain(result.chains[0]); // 기본값 세팅
    setNetwork(result.networks[0]); // 기본값 세팅
  }

  function changeChainHandler(e: any) {
    setChain(e.target.value);
  }

  function changeNetworkHandler(e: any) {
    setNetwork(e.target.value);
  }

  function inputHandler(keyword: String) {
    setSearchAddress(keyword);
  }

  function handleKeyPress(e: any) {
    if (e.key === 'Enter') {
      searchHandler();
    }
  }

  function searchHandler() {
    return getSearchPendingToken();
  }

  async function getSearchPendingToken() {
    popupStore.setIsLoading(true);
    const url = `/pending-tokens/${searchAddress}?chain=${chain}&network=${network}`;

    // 페이지 이동을 Link 대신 navigate를 사용하여 처리
    navigate(url);
    popupStore.setIsLoading(false);
  }

  // async function getSearchPendingToken() {
  //   popupStore.setIsLoading(true);
  //   // 0x574e9c26bda8b95d7329505b4657103710eb32ea
  //   const url = `/pending-tokens/${searchAddress}?chain=${chain}&network=${network}`;
  //   window.location.href = url;

  //   // // 요청 전 기존 페이지 이동 방식을 fetch로 수정
  //   // console.log('url', url);
  //   // const response = await fetch(url, {
  //   //   credentials: 'include'
  //   // });
  //   // console.log(`response ${JSON.stringify(response)}`);

  //   // if (!response.ok) {
  //   //   if (response.status === 401) {
  //   //     alertPopup(`로그인 정보 만료`);
  //   //   } else if (response.status === 404) {
  //   //     alertPopup(`대기중인 토큰 정보가 없습니다`);
  //   //   } else {
  //   //     alertPopup(`서버 에러`);
  //   //     console.error(`Error ${response.status}: ${response.statusText}`);
  //   //   }
  //   // } else {
  //   //   // 정상적으로 응답이 왔을 때 페이지 이동
  //   //   window.location.href = url;
  //   // }
  //   popupStore.setIsLoading(false);
  // }

  async function getPendingTokenList(params?: string) {
    popupStore.setIsLoading(true);

    const response = await (await getAPI(`pending-tokens${params}`)).json();

    if (response['status'] === '1') {
      setPendingTokenList(response.result);
      const { total, limit } = response.result;
      setMaxPage(calcMaxPage(limit, total));

      popupStore.setIsLoading(false);
    } else {
      popupStore.setIsLoading(false);
    }
  }

  return (
    <div className="PendingTokenPage">
      <>
        {!isLoading ? (
          <div className="pendingTokenTable">
            <div className="searchArea">
              <select
                className="chainList"
                defaultValue={chain}
                onChange={(e) => changeChainHandler(e)}
              >
                {chainList.map((c) => {
                  return (
                    <option value={c} key={c}>
                      {c}
                    </option>
                  );
                })}
              </select>
              <select
                className="networkList"
                defaultValue={network}
                onChange={(e) => changeNetworkHandler(e)}
              >
                {networkList.map((c) => {
                  return (
                    <option value={c} key={c}>
                      {c}
                    </option>
                  );
                })}
              </select>
              <input
                id="searchInput"
                type="search"
                value={searchAddress}
                onChange={(e) => inputHandler(e.target.value)}
                onKeyPress={(e) => handleKeyPress(e)}
                placeholder="Search"
                autoComplete="off"
              />
              <button className="searchBtn" onClick={() => searchHandler()}>
                Search
              </button>
            </div>

            <br></br>

            <ul>
              <li className="pendingTokenList th">
                <div className="pendingTokenItem th id">ID</div>
                <div className="pendingTokenItem th createdAt">CreatedAt</div>
                <div className="pendingTokenItem th chain">Chain</div>
                <div className="pendingTokenItem th network">Network</div>
                <div className="pendingTokenItem th address">Address</div>
              </li>

              {pendingTokenList.map((t: PendingToken, i) => {
                return (
                  <Link to={`/pending-tokens/${t.id}`}>
                    <li key={i} className="pendingTokenList td">
                      <div className="pendingTokenItem th id">{t.id}</div>
                      <div className="pendingTokenItem th createdAt">{t.createdAt}</div>
                      <div className="pendingTokenItem th chain">{t.chain}</div>
                      <div className="pendingTokenItem th network">{t.network}</div>
                      <div className="pendingTokenItem th address">{t.address}</div>
                    </li>
                  </Link>
                );
              })}
            </ul>
            <Paginator goTo={goTo} page={page} maxPage={maxPage} />
          </div>
        ) : (
          <div className="loadingArea">
            <img className="loadingImg" src={loadingImg} alt="" />
          </div>
        )}
      </>
    </div>
  );
}
