import { isEmpty } from 'lodash';

interface Props {
  searchKeyword: string;
  inputHandler: Function;
  handleKeyPress: Function;
  searchHandler: Function;
  searchTypeList?: Array<string> | undefined | null;
  searchType?: string | undefined | null;
  searchTypeHanlder?: Function | undefined | null;
}

export default function NftSearchInput(props: Props) {
  const {
    searchKeyword,
    inputHandler,
    handleKeyPress,
    searchHandler,
    searchTypeList,
    searchType,
    searchTypeHanlder
  } = props;

  function searchTypeChangeHanlder(e: any) {
    if (searchTypeHanlder) return searchTypeHanlder(e.target.value);
  }
  return (
    <div className="searchArea ">
      {!isEmpty(searchTypeList) && (
        <select
          className="searchType"
          onChange={(e) => searchTypeChangeHanlder(e)}
          value={searchType ? searchType : 'Address'}
        >
          {searchTypeList?.map((e) => {
            return (
              <option key={e} value={e}>
                {e}
              </option>
            );
          })}
        </select>
      )}

      <input
        id="searchInput"
        className={`isSearchType ${!isEmpty(searchTypeList) ? 'short' : ''}`}
        type="search"
        value={searchKeyword}
        onChange={(e) => inputHandler(e.target.value)}
        onKeyPress={(e) => handleKeyPress(e)}
        placeholder="NFT Address"
        autoComplete="off"
      />

      <button onClick={() => searchHandler()}>Search</button>
    </div>
  );
}
