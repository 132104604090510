import React, { useState } from 'react';
import './TokenIdSearch.scss';
import SearchIcon from '@mui/icons-material/Search';
export default function TokenIdSearch(props: any) {
  const { getSearchResult } = props;
  const [keyword, setkeyword] = useState<any>('');

  function keywordChangeHandler(e: any) {
    const value = e.target.value;
    setkeyword(value);
  }

  function keyUpHandler(e: any) {
    if (e.key === 'Enter') {
      const search = `?tokenId=${keyword}`;
      getSearchResult(search);
    }
  }

  return (
    <div className="inputTokenIdSearch">
      <input
        type="search"
        className="inputSearch"
        placeholder="Token ID"
        autoComplete="off"
        value={keyword}
        onChange={(e) => keywordChangeHandler(e)}
        onKeyUp={(e) => keyUpHandler(e)}
        pattern="[0-9]{6}"
      />
      <button className="btnSearch" onClick={() => getSearchResult(`?tokenId=${keyword}`)}>
        <SearchIcon />
      </button>
    </div>
  );
}
