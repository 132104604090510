import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import EditIcon from '@mui/icons-material/Edit';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import EmailIcon from '@mui/icons-material/Email';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import ListIcon from '@mui/icons-material/List';

import './SettingPopup.scss';
import indexStore from '../../mobx/store';
import { API_HOST_PREFIX } from '../../constants/common';

export default function SettingPopup(props: any) {
  interface userListType {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    googleIdentity: string;
    isSuperAdmin: boolean;
    role: any;
  }
  const { settingToggle, closePopupHandler } = props;
  const { popupStore } = indexStore();

  const [adminClassList, setadminClassList] = useState([]);
  const [userList, setuserList] = useState([]);
  const [addpopupToggle, setaddpopupToggle] = useState(false);
  const [updatePopupToggle, setupdatePopupToggle] = useState(false);

  const [userEmail, setuserEmail] = useState('');
  const [userisSuperAdmin, setisSuperAdmin] = useState('2');
  const [userRole, setuserRole] = useState('ADMIN');
  const [userId, setuserId] = useState(0);

  useEffect(() => {
    if (settingToggle) {
      getUserList();
      getAdminClassList();
    }
  }, [settingToggle]);

  useEffect(() => {
    if (!addpopupToggle) {
      clearUserInfo();
    }
  }, [addpopupToggle]);

  useEffect(() => {
    if (!updatePopupToggle) {
      clearUserInfo();
    }
  }, [updatePopupToggle]);

  function clearUserInfo() {
    setaddpopupToggle(false);
    setupdatePopupToggle(false);
    setuserEmail('');
    setisSuperAdmin('2');
    setuserRole('ADMIN');
  }

  function confirmPopup(message: string, method?: Function) {
    popupStore.setConfirm(true);
    popupStore.setMessage(message);
    method ? popupStore.setMethod(method) : popupStore.setMethod(() => null);
    popupStore.setToggle(true);
  }

  function alertPopup(message: string) {
    popupStore.setConfirm(false);
    popupStore.setMessage(message);
    popupStore.setMethod(() => null);
    popupStore.setToggle(true);
  }

  async function getUserList() {
    const URL = `${API_HOST_PREFIX}/user`;
    await fetch(URL, {
      credentials: 'include'
    })
      .then((r) => r.json())
      .then((json) => {
        if (json.success === true) {
          setuserList(json.result);
        }
      });
  }

  async function getAdminClassList() {
    const URL = `${API_HOST_PREFIX}/roles`;
    await fetch(URL, {
      credentials: 'include'
    })
      .then((r) => r.json())
      .then((json) => {
        if (json.success === true) {
          setadminClassList(json.result);
        } else {
          console.warn(json.message);
        }
      });
  }

  async function AddUser() {
    const addUserObj = {
      email: userEmail,
      isSuperAdmin: userisSuperAdmin === '1' ? true : false,
      role: userRole
    };
    const URL = `${API_HOST_PREFIX}/user/create`;
    await fetch(URL, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(addUserObj)
    })
      .then((r) => r.json())
      .then((json) => {
        if (json.success === true) {
          getUserList();
          clearUserInfo();
        } else {
          alertPopup(json.message);
        }
      })
      .catch((e) => {
        console.warn(e);
        alertPopup(e.message);
      });
  }

  async function DeleteUser(userId: string | number) {
    const addUserObj = {
      userId: userId
    };
    const URL = `${API_HOST_PREFIX}/user/delete`;
    await fetch(URL, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(addUserObj)
    })
      .then((r) => r.json())
      .then((json) => {
        if (json.success === true) {
          getUserList();
          clearUserInfo();
        } else {
          alertPopup(json.message);
        }
      })
      .catch((e) => {
        console.warn(e);
        alertPopup(e.message);
      });
  }

  async function UpdateUser() {
    const addUserObj = {
      id: userId,
      isSuperAdmin: userisSuperAdmin === '1' ? true : false,
      role: userRole
    };
    const URL = `${API_HOST_PREFIX}/user/update`;
    await fetch(URL, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(addUserObj)
    })
      .then((r) => r.json())
      .then((json) => {
        if (json.success === true) {
          getUserList();
          clearUserInfo();
        } else {
          alertPopup(json.message);
        }
      })
      .catch((e) => {
        console.warn(e);
        alertPopup(e.message);
      });
  }

  function addAdminHandler() {
    confirmPopup('신규 관리자를 추가 하시겠습니까?', () => AddUser());
  }

  function removeAdminHandler(user: userListType) {
    confirmPopup('해당 관리자를 삭제 하시겠습니까?', () => DeleteUser(user.id));
  }

  function updateAdminHandler() {
    confirmPopup('관리자 정보를 수정 하시겠습니까?', () => UpdateUser());
  }

  function updateAdminClick(user: userListType) {
    setupdatePopupToggle(true);
    setuserEmail(user.email);
    setisSuperAdmin(user.isSuperAdmin ? '1' : '2');
    setuserId(user.id);
    setuserRole(user.role);
  }

  function userDetail(i: any) {
    let target = document.getElementsByClassName('userObj')[i];
    target.classList.toggle('on');
  }

  function changeClassHandler(e: any) {
    setuserRole(e.target.value);
  }

  const userObj = (i: number, user: userListType) => {
    return (
      <li key={i} className="userObj">
        {user.isSuperAdmin ? (
          <ManageAccountsIcon className="userIcon" onClick={() => userDetail(i)} />
        ) : (
          <AccountCircleIcon className="userIcon" onClick={() => userDetail(i)} />
        )}

        <span className="userEmail" onClick={() => userDetail(i)}>
          {user.email}
        </span>
        <PersonRemoveIcon onClick={() => removeAdminHandler(user)} className="userRemove" />
        <EditIcon onClick={() => updateAdminClick(user)} className="userMode" />

        <div className="userDetails">
          <div className="row">
            <span className="desc">Name</span>
            <span className="res">
              {user.lastName}
              {user.firstName}
            </span>
          </div>
          <div className="row">
            <span className="desc">Super Admin</span>
            <span className="res">{user.isSuperAdmin ? 'True' : 'False'}</span>
          </div>
          <div className="row">
            <span className="desc">Role</span>
            <span className="res">{user.role}</span>
          </div>
          <div className="row">
            <span className="desc">Google Identity</span>
            <span className="res">{user.googleIdentity}</span>
          </div>
        </div>
      </li>
    );
  };

  return (
    <div className={`blackBg ${settingToggle ? '' : 'close'}`}>
      <div className={`SettingPopup ${settingToggle ? '' : 'close'}`}>
        <div className="popupHeader">
          <span>Admin Setting</span>
          <button onClick={() => closePopupHandler()} className="btnClose">
            <CloseIcon />
          </button>
        </div>
        <div className="popupCont">
          <ul className="adminList">
            {userList.map((r, i) => {
              return userObj(i, r);
            })}
          </ul>
          <div className="add" onClick={() => setaddpopupToggle(true)}>
            <PersonAddIcon className="addIcon" />
          </div>
        </div>
        <div className="popupFooter"></div>

        {/* Add Admin */}
        {addpopupToggle && (
          <div className="addBlackbg">
            <div className="addPopup">
              <div className="popupHeader">
                <span>Add Admin</span>
                <button onClick={() => setaddpopupToggle(false)} className="btnClose">
                  <CloseIcon />
                </button>
              </div>
              <div className="popupCont">
                <div className="inputItem">
                  <AccountCircleIcon />
                  <span className="col">Admin ID</span>
                  <input
                    type="text"
                    value={userEmail}
                    onChange={(e) => setuserEmail(e.target.value)}
                  />
                </div>

                <div className="inputItem">
                  <WorkspacePremiumIcon />
                  <span className="col">Super Admin</span>

                  <label>
                    <input
                      type="radio"
                      name="superAdmin"
                      value={'1'}
                      checked={userisSuperAdmin === '1'}
                      onChange={() => setisSuperAdmin('1')}
                    />
                    <span>True</span>
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="superAdmin"
                      value={'2'}
                      checked={userisSuperAdmin === '2'}
                      onChange={() => setisSuperAdmin('2')}
                    />
                    <span>False</span>
                  </label>
                </div>

                <div className="inputItem">
                  <ListIcon />
                  <span className="col">Role</span>
                  <select
                    className="adminClassList"
                    defaultValue={userRole}
                    onChange={(e) => changeClassHandler(e)}
                  >
                    {adminClassList.map((e) => {
                      return (
                        <option value={e} key={e}>
                          {e}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="popupFooter">
                <button className="submit" onClick={() => addAdminHandler()}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        )}

        {/* modpopup */}
        {updatePopupToggle && (
          <div className="addBlackbg">
            <div className="addPopup">
              <div className="popupHeader">
                <span>Update Admin</span>
                <button onClick={() => setupdatePopupToggle(false)} className="btnClose">
                  <CloseIcon />
                </button>
              </div>
              <div className="popupCont">
                <div className="inputItem">
                  <AccountCircleIcon />
                  <span className="col">Admin ID</span>
                  <input
                    disabled
                    type="text"
                    value={userEmail}
                    onChange={(e) => setuserEmail(e.target.value)}
                  />
                </div>

                <div className="inputItem">
                  <WorkspacePremiumIcon />
                  <span className="col">Super Admin</span>

                  <label>
                    <input
                      type="radio"
                      name="superAdmin"
                      value={'1'}
                      checked={userisSuperAdmin === '1'}
                      onChange={() => setisSuperAdmin('1')}
                    />
                    <span>True</span>
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="superAdmin"
                      value={'2'}
                      checked={userisSuperAdmin === '2'}
                      onChange={() => setisSuperAdmin('2')}
                    />
                    <span>False</span>
                  </label>
                </div>

                <div className="inputItem">
                  <ListIcon />
                  <span className="col">Role</span>
                  <select
                    className="adminClassList"
                    defaultValue={userRole}
                    onChange={(e) => changeClassHandler(e)}
                  >
                    {adminClassList.map((e) => {
                      return (
                        <option value={e} key={e}>
                          {e}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="popupFooter">
                <button className="submit" onClick={() => updateAdminHandler()}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
