import Paginator from './Common/Paginator';
import './SyncFailedTable.scss';
import { isEmpty } from 'lodash';
import SyncButton from './SyncButton';
import { useState } from 'react';
import { useParams } from 'react-router';
import SyncAllButton from './SyncAllButton';
import { getQueryParamObj } from '../utils/util';
interface failedListType {
  failedList: Array<failedObj>;
  currentPage: number | string;
  maxPage: number | string;
  goTo: Function;
  isLoading: boolean;
  alertPopup: Function;
  searchType: string;
  filterSubmitHanlder: Function;
  menuName: string;
  refresh: Function;
}

interface failedObj {
  status: number;
  tokenAddress: string;
  tokenId: string | number;
}

export default function SyncFailedTable(props: failedListType) {
  const {
    failedList,
    currentPage,
    maxPage,
    goTo,
    isLoading,
    alertPopup,
    searchType,
    filterSubmitHanlder,
    menuName,
    refresh
  } = props;
  const params = useParams();

  // const [filterList] = useState<Array<string>>(['TokenID', 'TokenID Range']);
  const [selectedFilter, setselectedFilter] = useState<string>('TokenID');
  const [tokenIdValue, settokenIdValue] = useState<string>('');
  const [fromTokenId, setfromTokenId] = useState<string>('');
  const [toTokenId, settoTokenId] = useState<string>('');

  const tokenAddress = getQueryParamObj(window.location.search)?.tokenAddress || '';
  const statusKeyword = getQueryParamObj(window.location.search)?.status || '';
  // function filterChangeHandler(e: any) {
  //   setselectedFilter(e.target.value);
  // }

  function keyupHandler(e: any) {
    if (e.key === 'Enter') {
      filterSubmitHanlder(selectedFilter, tokenIdValue, fromTokenId, toTokenId);
    }
  }
  return (
    <>
      <div className="FailedTable">
        <div className="failedListfilterArea">
          {/* <select className="filter" onChange={(e) => filterChangeHandler(e)}>
              {filterList.map((e) => {
                return (
                  <option key={e} value={e}>
                    {e}
                  </option>
                );
              })}
            </select> */}

          <div>
            {(tokenAddress || statusKeyword) && (
              <SyncAllButton
                btnType={searchType}
                menuName={menuName}
                tokenAddress={tokenAddress}
                statusKeyword={statusKeyword}
                alertPopup={alertPopup}
                refresh={refresh}
              />
            )}
          </div>

          {searchType === 'Address' && tokenAddress && (
            <>
              {selectedFilter === 'TokenID' && (
                <input
                  type="search"
                  className="inputValue"
                  placeholder="TokenID"
                  value={tokenIdValue}
                  onChange={(e) => settokenIdValue(e.target.value)}
                  onKeyUp={(e) => keyupHandler(e)}
                />
              )}
              {selectedFilter === 'TokenID Range' && (
                <>
                  <input
                    type="text"
                    className="inputValue"
                    placeholder="From TokenID"
                    value={fromTokenId}
                    onChange={(e) => setfromTokenId(e.target.value)}
                  />
                  <input
                    type="text"
                    className="inputValue"
                    placeholder="To TokenID"
                    value={toTokenId}
                    onChange={(e) => settoTokenId(e.target.value)}
                  />
                </>
              )}
              <button
                className="submit"
                onClick={() =>
                  filterSubmitHanlder(selectedFilter, tokenIdValue, fromTokenId, toTokenId)
                }
              >
                submit
              </button>
            </>
          )}
        </div>

        <ul>
          <li className="failedList th">
            <div className="failedItem th col01">Address</div>
            <div className="failedItem th col02">Token ID</div>
            <div className="failedItem th col03">Status</div>
            <div className="failedItem th col04">Sync</div>
          </li>

          {failedList.map((e: failedObj, i) => {
            return (
              <li key={i} className="failedList td">
                <div className="failedItem td col01">{e.tokenAddress}</div>
                <div className="failedItem td col02">{e.tokenId}</div>
                <div className="failedItem td col03">{e.status}</div>
                <div className="failedItem td col04">
                  <SyncButton
                    syncType={menuName}
                    tokenAddress={e.tokenAddress}
                    tokenId={e.tokenId}
                    alertPopup={alertPopup}
                    refresh={refresh}
                  />
                </div>
              </li>
            );
          })}

          {!isLoading && isEmpty(failedList) && <li className="nodata">no data</li>}
        </ul>
      </div>

      <Paginator goTo={goTo} page={currentPage} maxPage={maxPage} />
    </>
  );
}
