import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import './DownloadPopup.scss';
import { API_HOST_PREFIX } from '../../constants/common';

export default function DownloadPopup(props: any) {
  const { toggle, closePopup } = props;
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  const [show, setshow] = useState(false);

  const [selectedYear, setselectedYear] = useState<number | string>(currentYear);
  const [selectedMonth, setselectedMonth] = useState<number | string>(currentMonth);

  const [yearList, setyearList] = useState<number[] | never[]>([]);
  const [monthList, setmonthList] = useState<number[] | never[]>([]);

  function initCurrentDate() {
    let arryear = [];
    let arrmonth = [];
    for (let i = currentYear - 2; i <= currentYear; i++) {
      arryear.push(i);
    }
    for (let i = 1; i <= currentMonth; i++) {
      arrmonth.push(i);
    }

    setyearList(arryear);
    setmonthList(arrmonth);
  }
  useEffect(() => {
    initCurrentDate();
  }, []);

  useEffect(() => {
    setshow(toggle);
  }, [toggle]);

  useEffect(() => {
    let arrmonth = [];
    if (selectedYear < currentYear) {
      setselectedMonth('1');
      for (let i = 1; i <= 12; i++) {
        arrmonth.push(i);
      }
    } else {
      for (let i = 1; i <= currentMonth; i++) {
        arrmonth.push(i);
      }
    }

    setmonthList(arrmonth);
  }, [selectedYear]);

  function changeYearHandler(e: any) {
    setselectedYear(e.target.value);
  }

  function changeMonthHandler(e: any) {
    setselectedMonth(e.target.value);
  }

  async function getCsvData() {
    const param = `${selectedYear}-${selectedMonth < 10 ? `0${selectedMonth}` : selectedMonth}`;
    const URL = `${API_HOST_PREFIX}/download?date=${param}`;

    try {
      window.location.href = URL;
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <>
      {show && (
        <div className="DownloadPopup">
          <div className="popupCont">
            <div className="popupConthd">
              <h3>Download CSV Export</h3>
              <div className="popupClose" onClick={closePopup}>
                <CloseIcon />
              </div>
            </div>

            <div className="datePicker">
              <label>
                <p>
                  <CalendarMonthIcon />
                  Year
                </p>
                <select
                  className="select selectYear"
                  onChange={(e) => changeYearHandler(e)}
                  value={selectedYear}
                >
                  {yearList.map((r) => (
                    <option key={r} value={r}>
                      {r}
                    </option>
                  ))}
                </select>
              </label>

              <label>
                <p>
                  <CalendarMonthIcon />
                  Month
                </p>
                <select
                  className="select selectMonth"
                  onChange={(e) => changeMonthHandler(e)}
                  value={selectedMonth}
                >
                  {monthList.map((r) => (
                    <option key={r} value={r}>
                      {r}
                    </option>
                  ))}
                </select>
              </label>
              <button onClick={getCsvData}>Download</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
