export const isBase64 = (str: string) => {
  if (!str || str.trim() === '') {
    return false;
  }

  try {
    return window.btoa(window.atob(str)) === str;
  } catch (err) {
    return false;
  }
};

export const addPrefixToImageUrl = (url: string, mimetype: string) => {
  if (!isBase64(url)) {
    return url;
  }

  return `data:${mimetype};base64,${url}`;
};

export const getQueryParamObj = (search: string) => {
  if (!search) return {};
  let obj: any = {};
  try {
    let arr = search.replace('?', '').split('&');
    arr.map((r) => {
      let key = r.split('=')[0];
      let val = r.split('=')[1];
      return (obj[key] = val);
    });
  } catch (e) {
    console.warn(e);
  }
  return obj;
};

export const getQueryParamString = (obj: object) => {
  let str = '';
  for (const [index, [key, value]] of Object.entries(Object.entries(obj))) {
    if (value) {
      str = str + `${index === '0' ? '?' : '&'}${key}=${value}`;
    }
  }
  return str;
};

export const dataURLtoFile = (icon: string, mimeType: string, fileName: string) => {
  const dataUrl = `data:${mimeType};base64,${icon}`;
  let arr = dataUrl.split(',');
  let mime = (mimeType = dataUrl.split(',')[0].split(':')[1].split(';')[0]);
  let bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], fileName, { type: mime });
};
